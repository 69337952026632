<template>
  <div class="wrap">
    <img class="logo-img" src="../assets/logo@2x.png" alt="" />
    <div class="img-box">
      <img class="banner-title" src="../assets/bannertext@2x.png" alt="" />
    </div>
    <div class="content-box">
      <!-- C学堂是谁标题 -->
      <div class="title-box">
        <div class="title-content">
          <p>C学堂</p>
          <p>是谁</p>
        </div>
      </div>
      <!-- C学堂是谁内容 -->
      <div class="about-who">
        <div class="box-left-img">
          <img class="box-img" src="../assets/topl@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/topr@2x.png" alt="" />
        </div>
        <div class="about-who-content about-who-padding">
          <p>“C学堂”是CSDN推出的服务聚合平台，提供品牌宣传、资讯发布、线上直播、调研问卷、云课堂、私域会员等功能，能迅速放大教育培训、民办学校等机构的品牌影响力，帮助机构更高效的链接到海量精准客户并降低成本。</p>
        </div>
        <div class="box-left-img">
          <img class="box-img" src="../assets/bottoml@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/bottomr@2x.png" alt="" />
        </div>
      </div>
      <!-- 推出C学堂的目的 -->
      <div class="title-box margin-top">
        <div class="title-content">
          <p>推出</p>
          <p>C学堂的</p>
          <p>目的</p>
        </div>
      </div>
      <div class="about-who about-goal">
        <div class="box-left-img">
          <img class="box-img" src="../assets/topl@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/topr@2x.png" alt="" />
        </div>
        <div class="about-who-content">
          <!-- 获客难 -->
          <div class="customers">
            <!-- 获客难标题 -->
            <div class="customers-title">
              <div class="title-shape"></div>
              <p>获客难</p>
            </div>
            <div class="customers-box">
              <p>
                据相关机构不完全统计，客户流失率高，线索贵，转化差，留存复购难，已成为教育机构普遍面临的难题。
              </p>
              <div class="customers-img-box">
                <div class="customers-img customers-img1">
                  <img src="../assets/gethardIcon1@2x.png" alt="" />
                  <p>获客成本</p>
                  <p>增长400%</p>
                </div>
                <div class="customers-img customers-img2">
                  <img src="../assets/gethardIcon2@2x.png" alt="" />
                  <p>无效客户数量</p>
                  <p>提升33%</p>
                </div>
                <div class="customers-img">
                  <img src="../assets/gethardIcon3@2x.png" alt="" />
                  <p>平均转化率不足</p>
                  <p>千分之一</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 获客成本高 -->
          <div class="customers">
            <!-- 获客成本高 -->
            <div class="customers-title customers-title1">
              <div class="title-shape"></div>
              <p>获客成本高</p>
            </div>
            <div class="customers-box customers-box1">
              <img class="cost-img" src="../assets/higncostPic@2x.png" alt="" />
              <p>
                获客成本（广告+人员）=总收入x46%
              </p>
            </div>
          </div>
          <!-- 获客难成本高如何解决 -->
          <div class="customers">
            <!-- 获客难成本高如何解决标题 -->
            <div class="customers-title customers-title2">
              <div class="title-shape"></div>
              <p>获客难成本高如何解决？</p>
            </div>
            <div class="customers-box">
              <p>
                “C学堂”背靠C站（CSDN）3100万用户资源及行业影响力，通过为教育机构提供相关功能与服务，快速提高机构的品牌知名度并获取精准客户，从而有效解决获客难成本高的问题。
              </p>
            </div>
          </div>
        </div>
        <div class="box-left-img">
          <img class="box-img" src="../assets/bottoml@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/bottomr@2x.png" alt="" />
        </div>
      </div>
      <!-- C学堂背靠CSDN -->
      <div class="title-box margin-top">
        <div class="title-content">
          <p>C学堂背靠</p>
          <p>CSDN</p>
        </div>
      </div>
      <div class="about-who about-goal">
        <div class="box-left-img">
          <img class="box-img" src="../assets/topl@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/topr@2x.png" alt="" />
        </div>
        <div class="about-who-content">
          <!-- 更精准 -->
          <div class="customers">
            <!-- 更精准 -->
            <div class="customers-title">
              <div class="title-shape"></div>
              <p>更精准</p>
            </div>
            <div class="customers-box">
              <p class="backer-p">
                中国<span class="backer-span">专业的IT</span>社区和服务平台；
              </p>
              <p class="backer-p">
                Alexa全球网站综合排名<span class="backer-span">第26位</span>；
              </p>
              <p class="backer-p">
                高覆盖客户：涵盖中国<span class="backer-span">90%</span
                >以上软件开发者和<span class="backer-span">70%</span
                >以上IT专业人士；
              </p>
              <p class="backer-p">
                高粘性客户：每月超过<span class="backer-span">30万</span
                >用户原创文章和<span class="backer-span">48万</span>评价；
              </p>
            </div>
          </div>
          <!-- 更海量 -->
          <div class="customers">
            <!-- 更海量 -->
            <div class="customers-title customers-title-top">
              <div class="title-shape"></div>
              <p>更海量</p>
            </div>
            <div class="customers-box">
              <p class="backer-p">
                <span class="backer-span1">3100万</span>注册会员
              </p>
              <p class="backer-p">
                <span class="backer-span1">1000万/月</span>活跃用户
              </p>
              <p class="backer-p">
                <span class="backer-span1">4万/日均</span>新增用户
              </p>
              <p class="backer-p">
                日均PV<span class="backer-span1">2400万</span>
              </p>
              <p class="backer-p">
                合作<span class="backer-span1">上千家</span>科技公司
              </p>
              <p class="backer-p">
                <span class="backer-span1">60万</span>注册企业及合作伙伴
              </p>
            </div>
          </div>
        </div>
        <div class="box-left-img">
          <img class="box-img" src="../assets/bottoml@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/bottomr@2x.png" alt="" />
        </div>
      </div>
      <!-- C学堂功能包 -->
      <div class="title-box margin-top">
        <div class="title-content">
          <p>C学堂</p>
          <p>功能包</p>
        </div>
      </div>
      <div class="about-who about-goal">
        <div class="box-left-img">
          <img class="box-img" src="../assets/topl@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/topr@2x.png" alt="" />
        </div>
        <div class="about-who-content">
          <div class="customers">
            <p class="feature-pack-text">
              C学堂提供给机构合作伙伴专属的在线社区，享受CSDN内容审批“绿色通道”服务，使得机构能构建自己的专属私域流量池。
            </p>
            <!-- 社区统一管理权限 -->
            <div class="customers-title community-title">
              <div class="title-shape"></div>
              <p>社区统一管理权限</p>
            </div>
            <div class="customers-title domain-name">
              <div class="title-shape"></div>
              <p>独立二级域名</p>
            </div>
            <div class="customers-box">
              <p>
                使用xx.csdn.net
                二级域名，并可在此二级域名下设，博客、直播、问答、论坛等子板块。
              </p>
            </div>
            <div class="customers-title navigation-title">
              <div class="title-shape"></div>
              <p>自定义导航</p>
            </div>
            <div class="customers-title content-title">
              <div class="title-shape"></div>
              <p>发布内容</p>
            </div>
            <div class="customers-box">
              <ul class="content-list">
                <li>技术博客</li>
                <li>在线直播/活动</li>
                <li>用户调研</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="box-left-img">
          <img class="box-img" src="../assets/bottoml@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/bottomr@2x.png" alt="" />
        </div>
      </div>
      <!-- 品牌入驻标题 -->
      <div class="title-box margin-top">
        <div class="title-content">
          <p>品牌</p>
          <p>入驻</p>
        </div>
      </div>
      <!-- 品牌入驻内容 -->
      <div class="about-who">
        <div class="box-left-img">
          <img class="box-img" src="../assets/topl@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/topr@2x.png" alt="" />
        </div>
        <div class="about-who-content aboout-brand-img">
          <img class="brand-img" src="../assets/brandpic@2x.png" alt="" />
        </div>
        <div class="box-left-img">
          <img class="box-img" src="../assets/bottoml@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/bottomr@2x.png" alt="" />
        </div>
      </div>
      <!-- C学堂功能包 -->
      <div class="title-box margin-top">
        <div class="title-content">
          <p>C学堂</p>
          <p>增值服务</p>
        </div>
      </div>
      <div class="about-who about-goal">
        <div class="box-left-img">
          <img class="box-img" src="../assets/topl@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/topr@2x.png" alt="" />
        </div>
        <div class="about-who-content">
          <div class="customers">
            <!-- 社区统一管理权限 -->
            <div class="customers-title collage">
              <div class="title-shape"></div>
              <p>IT技术高校行</p>
            </div>
            <div class="customers-box">
              <p>
                支持全年2场全国高校行活动参与推荐
              </p>
            </div>
            <div class="customers-title content-title">
              <div class="title-shape"></div>
              <p>技术直播</p>
            </div>
            <div class="customers-box">
              <p>
                支持全年4场在线直播
              </p>
            </div>
          </div>
        </div>
        <div class="box-left-img">
          <img class="box-img" src="../assets/bottoml@2x.png" alt="" />
          <div class="box-background"></div>
          <img class="box-img" src="../assets/bottomr@2x.png" alt="" />
        </div>
      </div>
      <!-- 扫码了解更多 -->
      <div class="more-box">
        <div class="topl-box"></div>
        <div class="topr-box"></div>
        <div class="bottoml-box"></div>
        <div class="bottomr-box"></div>
        <div class="more-box-left">
          <p>联系人：娜美</p>
          <p>电话：13683225223</p>
        </div>
        <div class="more-box-right">
          <img src="../assets/qrcode@2x.png" alt="" />
          <p>扫码了解更多</p>
        </div>
      </div>
      <img class="footer-img" src="../assets/linefooter@2x.png" alt="" />
    </div>
    <!-- 纹理背景 -->
    <img class="wenli1-img" src="../assets/wenli1@2x.png" alt="" />
    <img class="wenli2-img" src="../assets/wenli2@2x.png" alt="" />
    <img class="wenli3-img" src="../assets/wenli3@2x.png" alt="" />
  </div>
</template>
<script>
export default {
  name: "Main",
};
</script>

<style>
* {
  font-family: Helvetica, Tahoma, Arial, "PingFang SC", "Heiti SC",
    "Microsoft YaHei";
}
.margin-top {
  margin-top: 50.5px;
}
.logo-img {
  width: 84.6px;
  position: absolute;
  top: 9px;
  right: 9px;
}
.wrap {
  width: 375px;
  background-color: #023497;
  position: relative;
  background-image: url("../assets/bg@2x.jpg");
  background-size: 375px 1029.5px;
  background-repeat: no-repeat;
  padding-top: 99px;
}
.img-box {
  width: 375px;
  height: 139px;
  display: flex;
  justify-content: center;
}
.banner-title {
  width: 300px;
  height: 139px;
}
.content-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 85.5px 20px 0 20px;
}
.title-box {
  width: 130.5px;
  height: 72.5px;
  background-image: url("../assets/bigbt@2x.png");
  background-size: 130.5px 72.5px;
  font-family: PangMenZhengDao;
  font-size: 21px;
  color: #ffffff;
  line-height: 21px;
  text-align: center;
  position: relative;
}
.title-content {
  position: absolute;
  left: 0px;
  top: -10px;
}
.title-content p {
  width: 130.5px;
  font-family: PangMenZhengDao;
  font-size: 21px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.about-who {
  width: 339px;
  padding: 10px 16px;
  margin-top: 20px;
  position: relative;
  z-index: 10;
}
.box-img {
  width: 19.5px;
  height: 19.5px;
}
.topl-img {
  position: absolute;
  top: 0;
  left: 0;
}
.topr-img {
  position: absolute;
  top: 0;
  right: 0;
}
.bottoml-img {
  position: absolute;
  bottom: 0;
  left: 0;
}
.bottomr-img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.about-who-content {
  width: 339px;
  padding: 0 12px;
  box-sizing: border-box;
  font-size: 16px;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.05);
  letter-spacing: 0;
  line-height: 23px;
}
.aboout-brand-img {
  padding: 0 18.5px;
}
.about-who-padding {
  padding: 0 16px;
}
.text-decoration {
  color: #00f9ff;
  display: inline-block;
  position: relative;
}
.underline {
  width: 64px;
  height: 3px;
  border-radius: 1.5px;
  background-color: #032e90;
  position: absolute;
  bottom: 1px;
}
.about-goal {
  padding: 10px 12px;
}
.customers {
  margin: 0 auto;
}
.customers-box {
  width: 315px;
  margin-top: 11.5px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 10px 12.5px 10px 12.5px;
  box-sizing: border-box;
}
.customers-title {
  width: 80px;
  height: 26px;
  background: #032e90;
  border-radius: 10px 0 10px 0;
  position: relative;
}
.customers-title1 {
  width: 112px;
  margin-top: 10px;
}
.customers-title-top {
  margin-top: 10px;
}
.community-title {
  margin-top: 15px;
  width: 160px;
}
.domain-name {
  width: 128px;
  margin-top: 15px;
}
.collage {
  width: 126px;
}
.navigation-title {
  width: 112px;
  margin-top: 15px;
}
.content-title {
  width: 96px;
  margin-top: 15px;
}
.customers-box-bottom {
  margin-bottom: 5px;
}
.customers-title2 {
  width: 208px;
  margin-top: 10px;
}
.customers-title p {
  line-height: 28px;
  margin-left: 22px;
}
.title-shape {
  position: absolute;
  width: 18px;
  height: 26px;
  background: #ff9966;
  border-radius: 10px 0 10px 0;
}
.customers-img-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.customers-img {
  width: 95px;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.customers-img1 {
  width: 69px;
  margin-right: 15px;
}
.customers-img2 {
  width: 75px;
}
.customers-img img {
  width: 69px;
  height: 69px;
  margin-bottom: 7.5px;
}
.customers-img p {
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.cost-img {
  width: 270.5px;
  height: 111px;
}
.customers-box1 {
  padding: 20px 22.5px 15.5px 22px;
}
.customers-box1 p {
  margin-top: 10px;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.backer-p {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0;
  line-height: 34px;
}
.backer-span {
  color: #00f9ff;
}
.backer-span1 {
  color: #ff9966;
}
.feature-pack-text {
  width: 290px;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  margin: 0 auto;
}
.content-list > li {
  list-style-type: none;
  background: url("../assets/triangle@2x.png") no-repeat 0 7px;
  background-size: 7px 8px;
  text-indent: 15px;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 23px;
}
.brand-img {
  width: 302px;
  height: 251px;
  margin-top: 20.5px;
}
.more-box {
  width: 339px;
  height: 158px;
  box-sizing: border-box;
  margin-top: 50.5px;
  padding: 15px 20px 9px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgba(255, 255, 255, 0.05);
}
.more-box-left {
  width: 188px;
  margin-top: -20px;
}
.more-box-left p {
  color: #fff;
  font-size: 14px;
  line-height: 35px;
}
.more-box-right {
  width: 110px;
  height: 110px;
  margin-top: -20px;
}
.more-box-right p {
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  line-height: 17px;
}
.more-box-right img {
  width: 110px;
  height: 110px;
}
.wenli1-img {
  width: 156px;
  position: absolute;
  bottom: 1870px;
  right: 0;
}
.wenli2-img {
  width: 158px;
  height: 230px;
  position: absolute;
  bottom: 935px;
  left: 0;
}
.wenli3-img {
  width: 96px;
  position: absolute;
  right: 0;
  bottom: 250px;
}
.footer-img,
footer {
  margin-top: 30px;
  width: 375px;
  height: 90px;
}
.box-img {
  width: 19.5px;
  height: 19.5px;
}
.box-background {
  width: 304px;
  height: 19.5px;
  background: rgba(255, 255, 255, 0.05);
}
.box-left-img {
  width: 339px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topl-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 12px solid #023497;
  border-right: 12px solid transparent;
}
.topr-box {
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 12px solid #023497;
  border-left: 12px solid transparent;
}
.bottoml-box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 12px solid #023497;
  border-right: 12px solid transparent;
}
.bottomr-box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
  border-bottom: 12px solid #023497;
  border-left: 12px solid transparent;
}
</style>
