<template>
  <div id="app">
    <Main></Main>
  </div>
</template>

<script>
import Main from './pages/index'
import './common/font/font.css'
export default {
  name: 'App',
  components: {
    Main
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
</style>
